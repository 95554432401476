import React, { FC } from 'react';
import { stringUtils } from 'gatsby-theme-husky/src/utils';
import { DangerouslySetInnerHtml } from 'gatsby-theme-husky/src/layout';
import { ITextBlockProps } from 'gatsby-theme-husky/src/components/TextBlock/model';

const TextBlock: FC<ITextBlockProps> = ({ html, className, element = 'div' }) => {
  const indexedAndCapitalizedHtml = stringUtils.capitalizeEsp(html);

  return (
    <DangerouslySetInnerHtml
      className={`text-block ${className}`}
      element={element}
      html={className === 'article-section-title' ? indexedAndCapitalizedHtml : html}
    />
  );
};

export default TextBlock;
